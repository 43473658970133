import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import * as serviceWorker from "./serviceWorker";
import DEFAULT from "./assets/constants/deafaultconst";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  process.env.NODE_ENV === DEFAULT.NODE_ENV ? (
    <React.StrictMode>
      {/* <MsalProvider instance={msalInstance}> */}
      <App instance={msalInstance} />
      {/* </MsalProvider> */}
    </React.StrictMode>
  ) : (
    // <MsalProvider instance={msalInstance}>
    <App instance={msalInstance} />
    // </MsalProvider>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
