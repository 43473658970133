export const JUPYTERLAB_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://jptdev.maestro.maersk.com/`
    : `https://jptprd.maestro.maersk.com/`;

export const GITHUB_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://github.com/orgs/Maersk-Global/repositories`
    : `https://github.com/orgs/Maersk-Global/repositories`;

export const H2O_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://h2o-dev.maestro.maersk.com/`
    : `https://h2o.maestro.maersk.com/`;

export const KUBEFLOW_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://kbf-dev.maestro.maersk.com/`
    : `https://kbf.maestro.maersk.com/`;

export const AIRFLOW_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://af-dev.maestro.maersk.com/`
    : `https://af.maestro.maersk.com/`;

export const SPLINE_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://spline-dev.maestro.maersk.com/`
    : `https://spline.maestro.maersk.com/`;

export const SPARK_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://shs-dev.maestro.maersk.com/`
    : `https://shs.maestro.maersk.com/`;

export const MONITORING_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://monitor-dev.maestro.maersk.com/login`
    : `https://monitor-dl.maestro.maersk.com/login`;

export const CONFIGDRIVEN_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://afc-dev.maestro.maersk.com/`
    : `https://afc.maestro.maersk.com/`;

export const SELDONCORE_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://seldondev.maestro.maersk.com/seldon/default/sklearn/api/v1.0/doc/#/External%20Ambassador%20API/Predict `
    : `https://seldondev.maestro.maersk.com/seldon/default/sklearn/api/v1.0/doc/#/External%20Ambassador%20API/Predict `;

export const MLFLOW_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? `https://mfdev.maestro.maersk.com/`
    : `https://mfdev.maestro.maersk.com/`;
