import React from "react";
import { Button, Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequestNew } from "../../../../msalConfig";
import IMAGE from "../../../../assets/constants/Imageconstant";
import DEFAULT from "../../../../assets/constants/deafaultconst";

const NavLanding = () => {
  const { instance } = useMsal();
  const signIn = () => {
    instance
      .loginPopup(loginRequestNew)
      .then((res) =>
        sessionStorage.setItem(DEFAULT.MSAL_TOKEN_KEY, res.accessToken)
      )
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <nav role="navigation">
      <Container className="loggedin__user__padding">
        <img src={IMAGE.SIGINPAGELOGO} alt={"Datalab logo"} className="datalab-logo" />{" "}
        <ul id="signinnav">
          <li>
            <Button className="signin" onClick={() => signIn()}>
              Sign In
            </Button>
          </li>
        </ul>
      </Container>
    </nav>
  );
};

export default NavLanding;
