import React from "react";
import Typewriter from "typewriter-effect";
import "../../../assets/ux/assets/css/custom.css";
import { TYPEWRITERCONSTANT } from "../../../assets/constants/MainPageConstant";

const TypewriterMain = (props) => {
  return (
    <h1 className="py-2 maersk-mid-blue-text">
      Hi! I'm&nbsp;
      <Typewriter
        styles={{ display: "inline" }}
        onInit={(typewriter) => {
          typewriter.pauseFor(2500).deleteAll().start();
        }}
        options={{
          strings: TYPEWRITERCONSTANT,
          autoStart: true,
          loop: true,
        }}
      />
      <span className="wrap"></span>
    </h1>
  );
};

export default TypewriterMain;
