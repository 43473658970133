import React, { useEffect, useState } from "react";
import { Col, Card } from "react-bootstrap";
import IMAGE from "../../../assets/constants/Imageconstant";
import DEFAULT from "../../../assets/constants/deafaultconst";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { RECENT_ACTIVITY_URL } from "../serverConfig";
import { protectedResources } from "../../../msalConfig";
import { ERRORS } from "../../../assets/constants/ErrorConstants";
import MsalService from "../../../MsalService";
import _ from "lodash";
import ReactTimeAgo from "react-time-ago";
import { TbSpeakerphone } from "react-icons/tb";
// import * as timeago from "timeago.js";

// // import it first.
// import vi from "timeago.js/lib/lang/vi";

// register it.
import TimeAgo from "javascript-time-ago";

// English.
import en from "javascript-time-ago/locale/en";
// import "intl/locale-data/jsonp/en-ZA";
const RecentActivities = (props) => {
  // timeago.register("vi", vi);
  TimeAgo.setDefaultLocale(en.locale);
  TimeAgo.addLocale(en);
  // Create formatter (English).
  const timeAgo = new TimeAgo("en-US");
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const requestedBy = account.username;
  const [recentdata, setRecentdata] = useState();
  const activityrequest = {
    scopes: protectedResources.apiMMD.scopes,
    account: account,
  };
  const getRecentActivities = (token) => {
    const msalService = new MsalService();
    const header = msalService.createHeader(token);
    axios({
      method: "get",
      url: `${RECENT_ACTIVITY_URL}getmyactivities?requestedby=${requestedBy}`,
      header,
    })
      .then((res) => {
        setRecentdata(_.sortBy(res.data, res.data.lastAccessed).reverse());
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.statusText === ERRORS.MSG.NOTFOUND
        ) {
          console.log("no image found for your profile");
        }
      });
  };

  const getMyRecentActivities = async () => {
    try {
      const token = (await instance.acquireTokenSilent(activityrequest))
        .accessToken;
      getRecentActivities(token);
    } catch (error) {
      if (error) {
        try {
          const token = (await instance.acquireTokenRedirect(activityrequest))
            .accessToken;
          getRecentActivities(token);
        } catch (error) {
          console.log("failed to generate acquire token redirect", error);
        }
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getMyRecentActivities();
    };
    fetchData();
  }, [instance]);
  return (
    <Col>
      {" "}
      <div className="recent">
        {/* <Card> */}
          {/* <Card.Header className="dataset__display text-center">
            <div className="recent__active text-center">
              <span className="user">
                {props.avatar ? (
                  <img
                    className="hexa"
                    src={"data:image/jpeg;base64, " + props.avatar}
                    alt="profile pic"
                  />
                ) : (
                  <img
                    className="hexa "
                    src={IMAGE.USERPROFILE}
                    alt="profile pic"
                  ></img>
                )}
              </span>
              <h2>{props.loggedusername}</h2>
            </div>
          </Card.Header> */}
          {/* <Card.Body> */}
            <div className="text-left">
              {/* <h2 className="recent__sub__header">Recent Activities</h2> */}
              {recentdata &&
                recentdata.map((recent, index) => {
                  return (
                    <div className="recent__category" key={index}>
                      {/* <img
                        src={IMAGE.RECENT_ACTIVITY.RECENT_CONTENT}
                        alt="All Recent activites"
                      /> */}
                      <div className="recent__icon">
                      <TbSpeakerphone color="white" fontWeight="lighter" fontSize={25} />
                      </div>
                      <h2>
                        <span>{DEFAULT.RECENT_ACCESS_CONST + " "}</span>
                        <Link to={recent.uri} key={index}>
                          <span className="recent__tool">
                            <strong>{recent.activityName}</strong>
                          </span>{" "}
                        </Link>
                        <br />
                        <span>
                          {timeAgo.format(new Date(recent.lastAccessed))}
                        </span>
                        {/* <ReactTimeAgo
                            date={new Date(recent.lastAccessed)}
                            // locale="en-US"
                            // locale="vi"
                            timeStyle="twitter"
                          /> */}
                      </h2>
                    </div>
                  );
                })}
            </div>
          {/* </Card.Body>
        </Card> */}
      </div>
    </Col>
  );
};

export default RecentActivities;
