import React from "react";
import { Col, Button } from "react-bootstrap";

const ContactSection = (props) => {
  return (
    <Col className="text-center maersk-regular-text">
      <h1 className="py-3 text-white">Have any questions or ideas?</h1>
      <p className="py-3 lh-35 text-white lead">
        We would love to hear what you think. Please drop us a line and we will
        get back to you as soon as possible.
      </p>
      <a
        href="mailto:XDMaestroPlatformCapability@maersk.com"
        className="text-white mail"
      >
        <Button className="contact-btn">Contact Us</Button>
      </a>
    </Col>
  );
};

export default ContactSection;
