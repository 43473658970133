import React from "react";
import "../assets/css/maersk-font.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import LoginPage from "./Login";
import Footer from "./App/Footer/Footer";
import NavLanding from "./App/NavComponent/NavBar/NavLanding";
import LandingPage from "./App/LandingContent/LandingPage";

const DlLandingPage = (props) => {

  const { accounts } = useMsal();
  let roles = accounts[0] && accounts[0].idTokenClaims.roles;

  return (
    <React.Fragment>
      {/* <Row> */}
        <UnauthenticatedTemplate>
          <div id="wrapper">
            <NavLanding />
            <LoginPage />
            <Footer />
          </div>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <LandingPage/>
          {/* <LandingContent isOpen={props.isOpen} setIsOpen={props.setIsOpen} /> */}
          {/* <MainPage /> */}
        </AuthenticatedTemplate>
      {/* </Row> */}
    </React.Fragment>
  );
};
export default DlLandingPage;
