import React, { useState } from "react";
import MainPage from "../LandingContent/MainPage";
import "../../../assets/ux/assets/css/styles.css";
import "../../../assets/scss/components/menu/_sidebar.scss";
import Footer from "../Footer/Footer";

const LandingContent = (props) => {
  const { isOpen, setIsOpen } = props;
  return (
    <>
      {/* <div className={isOpen ? "main-page" : ""}> */}
      {/* <NavRight isOpen={isOpen} setIsOpen={setIsOpen} />
        <SideBar isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <div className={isOpen ? "main-page" : "collapsed-main-page"}>
        <MainPage />
        {/* <Header /> */}
        {/* <Content /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default LandingContent;
