import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { faUserClock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RecentActivities from "./RecentActivities";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { Buffer } from "buffer";
import { GRAPHME_URI } from "../serverConfig";
import { protectedResources } from "../../../msalConfig";
import { ERRORS } from "../../../assets/constants/ErrorConstants";
import { AiOutlineLogout } from "react-icons/ai";

const Header = () => {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  const { instance, accounts } = useMsal();
  const account = instance.getActiveAccount();
  const loggedusername = accounts.map((name) => name.name);
  const [avatar, setAvatar] = useState();
  const request = {
    scopes: protectedResources.graphMe.scopes,
    account: account,
  };

  const getProfile = (token) => {
    axios({
      method: "get",
      url: GRAPHME_URI,
      headers: {
        Authorization: `bearer ${token}`,
      },
      responseType: "arraybuffer",
    })
      .then((res) => {
        const avatar = Buffer.from(res.data).toString("base64");
        setAvatar(avatar);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.statusText === ERRORS.MSG.NOTFOUND
        ) {
          console.log("no image found for your profile");
        }
      });
  };

  const getMyProfile = async () => {
    try {
      const token = (await instance.acquireTokenSilent(request)).accessToken;
      getProfile(token);
    } catch (error) {
      if (error) {
        try {
          const token = (await instance.acquireTokenRedirect(request))
            .accessToken;
          getProfile(token);
        } catch (error) {
          console.log("failed to generate acquire token redirect", error);
        }
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getMyProfile();
    };
    fetchData();
  }, [instance]);

  return (
    <>
      <Button
        // id="mobile"
        className="logout-button"
        onClick={(event) =>
          instance.logoutPopup().catch((e) => {
            console.error(e);
          })
        }
      >
        {/* <AiOutlineLogout size={35} className="logout-icon" /> */}
      </Button>
    </>
  );
};

export default Header;
