import React, { useEffect, useState } from "react";
import Sidebar from "react-bootstrap-sidebar-menu";
import { menuItem } from "../assets/constants/SidebarConstant";
import "../assets/scss/components/menu/_sidebar.scss";
import { AiOutlineArrowDown } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import SignOut from "./App/NavComponent/NavBar/NavRight/SignOut";
import Header from "./App/NavComponent/Header";
import { Navbar } from "react-bootstrap";
import IMAGE from "../assets/constants/Imageconstant";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

const SideBarMenu = (props) => {
  const { instance, accounts } = useMsal();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const roles = accounts.map((role) => role.idTokenClaims["roles"]);
    if (roles.length > 0 && roles[0]) {
      setRoles(roles[0]);
    }
  });

  const handleClick = () => {
    var a = document.getElementsByClassName("sidebar-menu-toggle");
    if (a[0].className === "sidebar-menu-toggle") {
      props.setIsOpen(false);
    } else {
      props.setIsOpen(true);
    }
  };

  const theme = "dark";
  return (
    <AuthenticatedTemplate>
      <>
        <Navbar
          className="sb-topnav navbar py-4 navbar-expand bg-transparent"
          expand="lg"
          // bg={theme}
          // variant={theme}
        >
          <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
            <div className="input-group">
              <SignOut />
            </div>
          </form>
          <ul className="navbar-nav ms-auto ms-md-0 ">
            <Header />
          </ul>
        </Navbar>
        <div className="main-wrapper">
          <Sidebar
            className="vh-100 maersk-regular-text"
            variant={theme}
            bg={theme}
            expand="sm"
          >
            <Sidebar.Collapse>
              <Sidebar.Header>
                <Sidebar.Brand className="responsive-header-logo">
                  <NavLink to="/datalab/landing">
                    <img
                      src={IMAGE.LOGO}
                      alt={"Datalab logo"}
                      className="datalab-logo-nav-open"
                    />
                  </NavLink>
                </Sidebar.Brand>
                <Sidebar.Brand className="responsive-header-icon">
                  <NavLink to="/datalab/landing">
                    <img
                      src={IMAGE.LOGOICON}
                      alt={"Datalab logo"}
                      className="datalab-logo-nav-open"
                    />
                  </NavLink>
                </Sidebar.Brand>
                <Sidebar.Toggle onClick={handleClick}></Sidebar.Toggle>
              </Sidebar.Header>
              {menuItem.children &&
                menuItem.children.map((item, index) => {
                  return item.accessTo ? (
                    roles && roles.some((v) => item.accessTo.includes(v)) ? (
                      <Sidebar.Body key={item.id}>
                        <Sidebar.Nav>
                          {item.children === undefined ? (
                            <Sidebar.Nav.Link
                              href={item.path}
                              target={item.target}
                            >
                              <Sidebar.Nav.Icon>{item.icon}</Sidebar.Nav.Icon>
                              <Sidebar.Nav.Title>
                                {item.title}
                              </Sidebar.Nav.Title>
                            </Sidebar.Nav.Link>
                          ) : (
                            <>
                              <Sidebar.Sub>
                                <Sidebar.Sub.Toggle>
                                  {item.icon}
                                  <Sidebar.Nav.Title>
                                    {item.title}
                                  </Sidebar.Nav.Title>

                                  <Sidebar.Nav.Icon className="sidebar-arrow-icon">
                                    <AiOutlineArrowDown></AiOutlineArrowDown>
                                  </Sidebar.Nav.Icon>
                                </Sidebar.Sub.Toggle>
                                <Sidebar.Sub.Collapse>
                                  {item.children &&
                                    item.children.map((submenu, index) => {
                                      return submenu.accessTo ? (
                                        roles &&
                                        roles.some((v) =>
                                          submenu.accessTo.includes(v)
                                        ) ? (
                                          <Sidebar.Nav key={submenu.id}>
                                            <Sidebar.Nav.Link
                                              href={submenu.path}
                                              target={submenu.target}
                                            >
                                              <Sidebar.Nav.Icon
                                                style={{ color: "white" }}
                                              >
                                                {submenu.icon}
                                              </Sidebar.Nav.Icon>
                                              <Sidebar.Nav.Title
                                                style={{ color: "white" }}
                                              >
                                                {submenu.name}
                                              </Sidebar.Nav.Title>
                                            </Sidebar.Nav.Link>
                                          </Sidebar.Nav>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      );
                                    })}
                                </Sidebar.Sub.Collapse>
                              </Sidebar.Sub>
                            </>
                          )}
                        </Sidebar.Nav>
                      </Sidebar.Body>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  );
                })}
            </Sidebar.Collapse>
          </Sidebar>
        </div>
      </>
    </AuthenticatedTemplate>
  );
};

export default SideBarMenu;
