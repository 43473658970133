/* MME APIs */
export const MME_SOURCESYSTEM_URL =
  process.env.REACT_APP_RUN_ENV === "development"
    ? "https://apiazewdmaerstrommebackend.azurewebsites.net/api/mme"
    : "https://apiazewpmaerstromdebackend.azurewebsites.net/api/mme";
// export const DATA_LINEAGE_URL = "http://localhost:8080/api/mme/datalineage";
export const DATA_LINEAGE_URL =
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_RUN_ENV === "development"
    ? "https://maestro-dev.insights.maersk.com/api/mme/datalineage"
    : "https://maestro-dev.insights.maersk.com/api/mme/datalineage";
export const GRAPHME_URI = `https://graph.microsoft.com/v1.0/me/photo/$value`;
// export const RECENT_ACTIVITY_URL = "http://localhost:8080/api/mme/activities/";
export const RECENT_ACTIVITY_URL =
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_RUN_ENV === "development"
    ? "https://maestro-dev.insights.maersk.com/api/mme/activities/"
    : "https://maestro-dev.insights.maersk.com/api/mme/activities/";
// https://maestro-dev.insights.maersk.com/maestrobi/graph/batchsearch?searchPattern=sepha
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_RUN_ENV", process.env.REACT_APP_RUN_ENV);
export const MAESTRO_BI_URL =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_RUN_ENV === "development"
    ? "https://maestro-dev.insights.maersk.com/maestrobi/graph/batchsearch"
    : "https://maestro.insights.maersk.com/maestrobi/graph/batchsearch";
export const MAESTRO_INFRA_URL =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_RUN_ENV === "development"
    ? "https://maestro-dev.insights.maersk.com/infra"
    : "https://maestro.insights.maersk.com/infra";
