import React, { useState } from "react";
import "../../../assets/ux/assets/css/styles.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Row, Col, Card } from "react-bootstrap";
import IMAGE from "../../../assets/constants/Imageconstant";
import {
  HiOutlineLightBulb,
  // HiOutlineChevronLeft,
  // HiOutlineChevronRight,
  HiOutlineUserPlus,
  HiOutlineInboxStack,
} from "react-icons/hi2";
import { FiFileText } from "react-icons/fi";
import { TfiReceipt } from "react-icons/tfi";
import { IoSettingsOutline, IoFileTrayOutline } from "react-icons/io5";
import { MdScreenshotMonitor } from "react-icons/md";
import { SPARKCONST } from "../../../assets/constants/MainPageConstant";

const SliderCarousel = () => {

  const myRenderItem1 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.SPARKK8SIMAGE}
              alt="Datalab_Img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Spark on K8s
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Spark on k8s leverages the containerization capabilities of
                    Kubernetes to package spark applications into containers and
                    execute them within a k8s cluster.
                  </li>
                  <li>
                    Each spark application is deployed as a set of containers,
                    including the spark drivers and executors ,which are
                    scheduled and managed by k8s.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem2 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.AIRFLOWIMAGE}
              style={{ height: "205px" }}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Data Orchestrator - Low Code
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Elyra with Airflow as dataflow orchestrator which extend the
                    capabilities of jupyterlab to enable the creation, editing
                    and execution of pipelines and workflows using visual
                    editors notebook based interface.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem3 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.CONFIGDRIVEN}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Data Orchestrator - Config Driven
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Running airflow DAG (directed acyclic graph)
                    programmatically, code and config driven helps to automate
                    the complex workflows, incorporate the decision making
                    logic, integrate with external systems and customize the
                    execution flow based on the specific requirements.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem4 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.KUBERNATESPROFILESIMAGE}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Single-user (dedicated) server profiles in Datalab
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Minimal Environment - Uses jupyter/minimal-notebook (which
                    has everything from jupyter/base-notebook) Python, Git, Vi,
                    Nano, tzdata, unzip etc
                  </li>
                  <li>
                    Data Science Environment - Uses jupyter/datascience-notebook
                    image (jupyter/scipy-notebook & jupyter/r-notebook),
                    Libraries (Julia, python, R, scipy, rpy2, HDFS, gradfly,
                    Rdatasets packages)
                  </li>
                  <li>
                    Spark environment - Uses jupyter/all-spark-notebook and its
                    ancestor images IRKernel, rcurl, sparklyr, ggplot2packages,
                    spylon-kernel
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem5 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.GITHUBACTIONSIMAGE}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Integration with GitHub and GitHub Actions
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Integration Apache spark with GitHub and GitHub actions
                    allows us to automate the spark jobs and workflows, making
                    easier to manage and collaborate on the Data products.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem6 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.SPARKLINEAGEIMAGE}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Spark Lineage
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Spark lineage typically represented as a directed acyclic
                    graph (DAG), where the nodes represent the data sources,
                    intermediate datasets and final output. The edges represent
                    the transformations applied to the data.
                  </li>
                  <li>
                    The lineage graph captures dependencies between different
                    stages and allows for tracking the back the data
                    transformation to the source data.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem7 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.SPARKHISTORYIMAGE}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                Monitoring Spark and Spark History
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Spark monitoring through spark UI and logging mechanism
                    enables tracking and analysing the runtime behaviours and
                    performance of the spark applications.
                  </li>
                  <li>
                    Spark history provides a historical view of completed spark
                    applications enable us to review and analyse past job
                    executions, behaviours of spark applications, identify
                    patterns and make informed decisions to improve performance,
                    optimize resource allocation and diagnose issues.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderItem8 = () => {
    return (
      <Col className="w-90 mx-auto my-4 d-sm-slider">
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="align-self-center order-lg-2 p-lg-3 p-xl-3"
          >
            <img
              src={IMAGE.FEATURES.KUBEFLOWIMAGE}
              alt="Datalab_img"
              className="img-fluid rounded-border5"
            />
          </Col>
          <Col md={12} lg={6} xl={6} className="p-3 align-self-center">
            <Col className="text-left maersk-regular-text">
              <h2 className="p-sm-5 pb-sm-0 p-md-5 pb-md-0 p-lg-3 p-xl-3 mb-0">
                KubeFlow
              </h2>
              <div className="p-sm-5 pb-sm-0 p-md-5 p-lg-3 p-xl-3 mb-0">
                <ul className="slider-list p-0">
                  <li>
                    Kubeflow an end-to-end Machine Learning(ML) platform for
                    Kubernetes and provides components for each stage in the ML
                    lifecycle, from exploration through to training and
                    deployment.
                  </li>
                  <li>
                    The different stages in a typical machine learning lifecycle
                    are represented with different software components in
                    Kubeflow, including model development, model training, model
                    serving, and automated machine learning.
                  </li>
                </ul>
              </div>
              {/* <p className="more-details">
                <a href="#">Read More </a>
              </p> */}
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  const myRenderThumbnail1 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <HiOutlineLightBulb
            fontSize={35}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">Spark on K8s</h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail2 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <IoFileTrayOutline
            fontSize={35}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">
            Data Orchestrator - Low Code
          </h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail3 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <IoSettingsOutline
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">
            Data Orchestrator - Config Driven
          </h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail4 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <HiOutlineUserPlus
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">
            Single-user server profiles in Datalab
          </h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail5 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <TfiReceipt
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">
            Integration with GitHub and Actions
          </h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail6 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <FiFileText
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">Spark Lineage</h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail7 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <MdScreenshotMonitor
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">
            Monitoring Spark and History
          </h5>
        </Card.Body>
      </>
    );
  };
  const myRenderThumbnail8 = () => {
    return (
      <>
        <Card.Body
          className="thumbnail-cards"
          style={{ backgroundColor: "#fff", height: "140px" }}
        >
          <HiOutlineInboxStack
            fontSize={30}
            style={{ marginBottom: "20px", marginTop: "25px" }}
          />
          <h5 className="text-wrap color-text-black">KubeFlow</h5>
        </Card.Body>
      </>
    );
  };

  const images = [
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail1,
      renderItem: myRenderItem1,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail2,
      renderItem: myRenderItem2,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail3,
      renderItem: myRenderItem3,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail4,
      renderItem: myRenderItem4,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail5,
      renderItem: myRenderItem5,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail6,
      renderItem: myRenderItem6,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail7,
      renderItem: myRenderItem7,
    },
    {
      thumbnail: "...",
      renderThumbInner: myRenderThumbnail8,
      renderItem: myRenderItem8,
    },
  ];

  return (
    <>
      <div className="thumbnail-carousel">
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          thumbnailPosition={"top"}
        />
      </div>
    </>
  );
};
export default SliderCarousel;
