import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import config from "./config";
import Wrapper from "./components/wrapper";

const App = ({ instance }) => {
  return (
      <Router basename={config.basename}>
        <MsalProvider instance={instance}>
          <Wrapper />
        </MsalProvider>
      </Router>
  );
};

export default App;
