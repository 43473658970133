import React, { useState } from "react";
import SidebarMenu from "../../Sidebar";
import LandingContent from "./LandingContent";

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <SidebarMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      <LandingContent isOpen={isOpen} />
    </>
  );
};

export default LandingPage;
