import React from "react";
import { Col, Row, Tab, Nav } from "react-bootstrap";
import Dataops from "../../../assets/ux/assets/img/dataops.png";
import "../../../assets/ux/assets/css/custom.css";
import {
  LANDINGCARDSHEADER,
  LANDINGCARDS,
  LANDINGCARDSCONTENT,
} from "../../../assets/constants/MainPageConstant";

const LandingCards = () => {
  return (
    <>
      {LANDINGCARDSHEADER &&
        LANDINGCARDSHEADER.map((key, item) => {
          return (
            <Col
              key={item}
              className="text-sm-center text-lg-center text-xl-center row-cols-lg-1 row-cols-xl-2"
            >
              <h1 className="pseudo_border mb-4">{key.HEADER}</h1>
              <p className="mx-auto p-lg-2 p-md-1 lead mb-0 mt-4">{key.PARA}</p>
            </Col>
          );
        })}
      <Col className="m-sm-0">
        {/* <nav className=""> */}
        <Tab.Container>
          <Row className="md-lg-0 mt-md-3 mt-sm-3">
            <Nav
              defaultActiveKey="first"
              className="nav nav-tabs p-2 maersk-regular-text"
              id="nav-tab"
              role="tablist"
            >
              {LANDINGCARDS &&
                LANDINGCARDS.map((key, item) => {
                  return (
                    <Nav.Item
                      key={item}
                      className="nav-link"
                      id={key.ID}
                      data-bs-toggle="tab"
                      data-bs-target={key.DATATARGET}
                      type="button"
                      role="tab"
                      aria-controls={key.CONTROLS}
                      aria-selected="false"
                    >
                      <Nav.Link eventKey={key.EVENTKEY}>
                        <img
                          src={key.IMAGE}
                          alt="icon img"
                          className="img-thumbnail-1 mx-auto d-none d-lg-block d-md-none d-sm-none"
                        />
                        <h6 className="display-7 text-center mb-0 py-3">
                          {key.NAVNAME}
                        </h6>
                        <div className="selected"></div>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Row>
          <Row>
            <Tab.Content
              className="tab-content bg-transparent"
              id="nav-tabContent"
              defaultChecked="first"
            >
              {LANDINGCARDSCONTENT &&
                LANDINGCARDSCONTENT.map((key, item) => {
                  return (
                    <Tab.Pane key={item} eventKey={key.EVENTKEY}>
                      <div
                        className="tab-pane fade active show"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <Row>
                          <Col md={6} lg={6} sm={6}>
                            <ul className="text-default lh-28 p-md-5 p-lg-4 p-sm-0 mb-0">
                              {key.FIRSTCONTENT.map((item, idx) => (
                                <li key={idx}>{item} </li>
                              ))}
                            </ul>
                          </Col>
                          <Col md={6} lg={6} sm={6}>
                            <ul className="text-default lh-28 p-md-5 p-lg-4 p-sm-0 mb-0">
                              {key.SECONDCONTENT.map((item, idx) => (
                                <li key={idx}>{item} </li>
                              ))}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  );
                })}
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Col>
    </>
  );
};
export default LandingCards;
