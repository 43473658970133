import React, { Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";
import Loader from "./Loader";
import DEFAULT from "../assets/constants/deafaultconst";
import "../assets/scss/style.scss";
// import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import AccessError from ".././components/Alerts/AccessError";
import DllandingPage from "./DlLandingPage";
import LandingPage from "./App/LandingContent/LandingPage";
import DlLandingPage from "./DlLandingPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Wrapper = () => {
  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={route.component}
      />
    ) : null;
  });

  const { accounts } = useMsal();
  let roles = accounts[0] && accounts[0].idTokenClaims.roles;

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {roles && roles.includes(DEFAULT.DATALAB_USER_ROLE) || roles && roles.includes(DEFAULT.DATALAB_ADMIN_ROLE) ? (
          <>
            <Suspense fallback={<Loader />}>
              <Routes>
                {menu}
                <Route
                  path="/"
                  element={
                    <>
                      {/* <LandingPage/> */}
                      <DlLandingPage />
                    </>
                  }
                />
              </Routes>
              <ToastContainer/>
            </Suspense>
          </>
        ) : (
          // <Routes>
          <AccessError />
          // </Routes>
        )}
      </AuthenticatedTemplate>
      {/* <AuthenticatedTemplate>
        {roles && roles.includes(DEFAULT.DATALAB_ADMIN_ROLE) ? (
          
          <Suspense fallback={<Loader />}>
            <Routes>
              {menu}
              <Route path="/" element={<DllandingPage />} />
            </Routes>
          </Suspense>
        ) : (
          // <Routes>
          <AccessError />
          // </Routes>
        )}
        </AuthenticatedTemplate> */}
      <UnauthenticatedTemplate>
        <Suspense fallback={<Loader />}>
          <Routes>
            {menu}
            <Route
              path="/"
              element={
                <>
                  <DllandingPage />
                  {/* <Footer/> */}
                </>
              }
            />
          </Routes>
        </Suspense>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};
export default Wrapper;
