import React from "react";

const LoginPage = () => {
  return (
    <>
          <div className="main__container">
              <h4 className="text-center datalab-name">Maestro Data Lab</h4>
              <h4 className="text-center datalab-small-des">
                Empowers Enterprise Data Platform
              </h4>
          </div>
    </>
  );
};

export default LoginPage;
