import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import maestro from "../../../assets/images/Maestro_footer.png";
import "../../../assets/ux/assets/css/custom.css";
import moment from "moment";

const Footer = (props) => {
  const today = new Date();
  const date = moment(today);
  let todaydate = date.format("YYYY");
  const { isOpen, setIsOpen } = props;

  return (
    <footer className= "py-4 mt-auto footer">
    {/* <footer className={ */}
      {/* isOpen ? "main-page py-4 mt-auto footer" : "py-4 mt-auto footer"
     }> */}
      <Container className="px-4 maersk-regular-text">
        <Row>
          <Col xl={6} lg={6} md={6} xs={12} className="text-white">
            &copy; {todaydate} A.P. Moller - Maersk, Inc. All rights reserved.
          </Col>
          <Col xl={6} lg={6} md={6} xs={12} className="text-right text-white">
            Powered by <img src={maestro} className="maestro-logo"></img>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;