import React from "react";

const AccessError = () => {
  return (
    <h5 className="alert alert-danger alert-dismissible fade show">
      Unauthorised Access. Please contact Platform Capability Team.
    </h5>
  );
};

export default AccessError;
