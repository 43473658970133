import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

/* const DataLineage = React.lazy(() =>
  import("./components/App/DataLineage/ContentNew")
); */
const Login = React.lazy(() => import("./components/Login"));
const Landing = React.lazy(() => import("./components/DlLandingPage"));
const ComingSoon = React.lazy(() =>
  import("./components/App/LandingContent/ComingSoonPage")
);
const H2OFlow = React.lazy(() =>
  import("./components/App/IFramedComponents/H2OIFrame")
);
// const CreateRepo = React.lazy(() => import("./components/App/Repositories/CreateRepo"));
const PipelineDasboards = React.lazy(() =>
  import("./components/App/PipelineDashbaords/index")
);
const RegisterProduct = React.lazy(() =>
  import("./components/App/Products/ProductRegistration/index")
);
const ProductCatalogue = React.lazy(() =>
  import("./components/App/Products/ProductCatalogue/index")
);
const IndividualProduct = React.lazy(() =>
  import("./components/App/Products/IndividualProduct/index")
);
const Releases = React.lazy(() =>
  import("./components/App/Releases/ReleasePage")
);
const ViewAllProducts = React.lazy(() =>
  import(
    "./components/App/Products/ProductCatalogue/ViewAllProducts/ViewAllDataFetch"
  )
);

// const SHS = React.lazy(() =>
//   import("./components/App/IFramedComponents/SparkIFrame")
// );
const Spline = React.lazy(() =>
  import("./components/App/IFramedComponents/SplineIFrame")
);
// const JupyterHub = React.lazy(() =>
//   import("./components/App/IFramedComponents/JupyterHub")
// );
// const KubeFlow = React.lazy(() =>
//   import("./components/App/IFramedComponents/KubeFlow")
// );
// const AirFlow = React.lazy(() =>
//   import("./components/App/IFramedComponents/AirflowIFrame")
// );
// const Monitoring = React.lazy(() =>
//   import("./components/App/IFramedComponents/MonitoringIFrame")
// );

const routes = [
  /*  {
    path: "/datalab/applications/datalineage",
    exact: true,
    name: "Data lineage",
    component: <DataLineage />,
  }, */
  { path: "/login", exact: true, name: "Landing Page", component: <Login /> },
  {
    path: "/datalab/landing",
    exact: true,
    name: "Landing Page",
    component: <Landing />,
  },
  {
    path: "/datalab/mlops/h2oflow",
    exact: true,
    name: "H2O",
    component: <H2OFlow />,
  },
  // {
  //   path: "/datalab/dataops/sparkhistory",
  //   exact: true,
  //   name: "SHS",
  //   component: <SHS />,
  // },
  {
    path: "/datalab/dataops/sparklineage",
    exact: true,
    name: "Spline",
    component: <Spline />,
  },
  // {
  //   path: "/datalab/compute",
  //   exact: true,
  //   name: "Jupyter Hub",
  //   component: <JupyterHub />,
  // },
  // {
  //   path: "/datalab/pipeline/model",
  //   exact: true,
  //   name: "Model Pipeline",
  //   component: <KubeFlow />,
  // },
  // {
  //   path: "/datalab/pipeline/data",
  //   exact: true,
  //   name: "Data Pipeline",
  //   component: <AirFlow />,
  // },
  {
    path: "/datalab/costmanagement",
    exact: true,
    name: "Cost Management",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/mlops/mlflow",
    exact: true,
    name: "ML Flow",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/mlops/seldoncore",
    exact: true,
    name: "Seldon Core",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/mlops/feast",
    exact: true,
    name: "Feast",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/mlops/flyte",
    exact: true,
    name: "Flyte",
    component: <ComingSoon />,
  },
  // {
  //   path: "/datalab/repositories/createrepo",
  //   exact: true,
  //   name: "Create Repo",
  //   component: <CreateRepo />,
  // },
  {
    path: "/datalab/pipeline/dashboards",
    exact: true,
    name: "Pipeline Dashboards",
    component: <PipelineDasboards />,
  },
  {
    path: "/datalab/products/registerproduct",
    exact: true,
    name: "Register Product",
    component: <RegisterProduct />,
  },
  {
    path: "/datalab/products/productcatalogue",
    exact: true,
    name: "Product Catalogue",
    component: <ProductCatalogue />,
  },
  {
    path: "/datalab/products/:name",
    exact: true,
    name: "Individual Product",
    component: <IndividualProduct />,
  },
  {
    path: "/datalab/feedback",
    exact: true,
    name: "Feedback",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/wiki",
    exact: true,
    name: "Wiki",
    component: <ComingSoon />,
  },
  {
    path: "/datalab/releases",
    exact: true,
    name: "Releases",
    component: <Releases />,
  },
  {
    path: "/datalab/viewallproducts",
    exact: true,
    name: "ViewAllProducts",
    component: <ViewAllProducts />,
  },

  // {
  //   path: "/datalab/dataops/monitoringandalert",
  //   exact: true,
  //   name: "Monitoring & Alert",
  //   component: <Monitoring />,
  // },
];

export default routes;
