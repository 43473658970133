const IMAGE = {
  MAINSECTION: {
    MAINHEROIMAGE: require("../ux/assets/img/main-hero.png"),
  },
  SOLUTIONS: {
    SOLUTIONSIMAGE: require("../ux/assets/img/sample-img.png"),
  },
  NAVLANDINGCARDS: {
    FIRSTHOMEIMAGE: require("../../assets/images/Cost_Security_Control.png"),
    SECONDPROFILEIMAGE: require("../../assets/images/integration_experimentation.png"),
    THIRDCONTACTIMAGE: require("../../assets/images/Customization.png"),
    FOURTHACCESSIMAGE: require("../../assets/images/dataops.png"),
  },
  FEATURES: {
    MOBILEIMAGE: require("../../assets/images/mobile-img.png"),
    SYSTEMIMAGE: require("../../assets/images/system.png"),
    AIRFLOWIMAGE: require("../ux/assets/img/slider-7.png"),
    KUBERNATESPROFILESIMAGE: require("../ux/assets/img/slider-4.png"),
    SPARKHISTORYIMAGE: require("../ux/assets/img/slider-5.png"),
    SPARKK8SIMAGE: require("../ux/assets/img/slider-6.png"),
    SPARKLINEAGEIMAGE: require("../ux/assets/img/slider-1.png"),
    CONFIGDRIVEN: require("../ux/assets/img/slider-8.png"),
    GITHUBACTIONSIMAGE: require("../ux/assets/img/Slider-2.png"),
    KUBEFLOWIMAGE: require("../ux/assets/img/slider-3.png"),
    SPARKSLIDERIMAGE: require("../ux/assets/img/button1.png"),
    AIRFLOWDAGSLIDERIMAGE: require("../ux/assets/img/button2.png"),
    AIRFLOWSLIDERIMAGE: require("../ux/assets/img/button3.png"),
    PROFILESLIDERIMAGE: require("../ux/assets/img/button4.png"),
    GITHUBSLIDERIMAGE: require("../ux/assets/img/button5.png"),
    SPLINESLIDERIMAGE: require("../ux/assets/img/button6.png"),
    SPARKHISTORYSLIDERIMAGE: require("../ux/assets/img/button7.png"),
    KUBEFLOWSLIDERIMAGE: require("../ux/assets/img/button8.png"),
  },
  ACCESSMGMT: {
    ACCESSMGMTMENU: require("../images/Access-mgmt.png"),
    ACCESSMGMTSUBMENU: require("../images/Acees-mgmt-header.png"),
  },
  REPOCOMPUTE: {
    REPO: require("../images/Git.png"),
    INSIGHTS: require("../images/Insights.png"),
    COMPUTE: require("../images/Compute.png"),
  },

  ORCHESTRATOR: {
    GLOBALORCH: require("../images/Globalorch.png"),
    DATAORCH: require("../images/Dataorches.png"),
    CODEORCH: require("../images/Codeorches.png"),
  },
  CAPABILITY: {
    CAPABILITIES: require("../images/Capabl.png"),
    H2O: require("../images/H20.png"),
    POSTGRESQL: require("../images/Postgresql.png"),
    DREMIO: require("../images/Dremio.png"),
    DATAIKU: require("../images/Dataiku.png"),
  },

  COSTMONITORING: {
    COST: require("../images/Costing.png"),
    CLOUD: require("../images/Cloudability.png"),
    UNRAVEL: require("../images/Unravel.png"),
    ADX: require("../images/Azuredataexplorer.png"),
  },

  MONITORING: require("../images/Monitor.png"),
  LINEAGE: require("../images/Lineage.png"),
  CATALOG: require("../images/Catalog.png"),
  WIKI: require("../images/wiki.png"),

  RECENT_ACTIVITY: {
    RECENT_HEADER: require("../images/Recent.png"),
    RECENT_CONTENT: require("../images/Activities.png"),
  },
  SEARCH: require("../images/ic_search.png"),
  LOGO: require("../../assets/images/Logo.png"),
  LOGOICON: require("../../assets/images/logoimg.png"),
  SIGINPAGELOGO: require("../../assets/ux/assets/img/signin_logo.png"),
  LOGOUT: require("../images/signout.png"),
  USER: require("../images/user.png"),
  USERPROFILE: require("../images/user-circle.png"),
  CREATENEWICON: require("../images/Create New.png"),
  CONFIDENTIAL_ICON: require("../images/Confidential.png"),
  INTERNAL_ICON: require("../images/Internal.png"),
  PUBLIC_ICON: require("../images/Public.png"),
  PRODUCT_ICON: require("../images/Products.png"),
  LIST_ICON: require("../images/list.png"),
  GRID_ICON: require("../images/grid.png"),
  CALENDAR: require("../images/calender.png"),
};

export default IMAGE;
