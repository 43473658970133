import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  SOLUTIONCONSTANT,
  SOLUTIONHEADER,
} from "../../../assets/constants/MainPageConstant";

const SolutionsSection = (props) => {
  return (
    <>
      <Container className="">
        {SOLUTIONHEADER &&
          SOLUTIONHEADER.map((key, item) => {
            return (
              <Col
                key={item}
                className="text-sm-center text-lg-center text-xl-center row-cols-lg-1 row-cols-xl-2"
              >
                <h1 className="pseudo_border mb-4">{key.HEADER}</h1>
                <p className="mx-auto p-lg-2 p-md-1 lead my-4">
                  {key.PARA}
                </p>
              </Col>
            );
          })}
        {SOLUTIONCONSTANT &&
          SOLUTIONCONSTANT.map((key, item) => {
            return (
              <Row className="py-5" key={item}>
                {key.TRANSITION === "fadeInLeft" ? (
                  <>
                    <Col
                      md={12}
                      lg={6}
                      xl={6}
                      className="align-self-center text-center order-lg-2"
                    >
                      <img src={key.IMAGE} className="w-85 img-fluid fadeInLeft" />
                    </Col>
                    <Col
                      md={12}
                      lg={6}
                      xl={6}
                      className="py-3 align-self-center text-lg-start text-md-center text-sm-center order-lg-1"
                    >
                      <Col className="w-85 d-md-100 mx-auto fadeInRight">
                        <h2 className="mb-0">{key.HEADER}</h2>
                        <p className="mb-0 py-3">{key.CONTENT}</p>
                        {/* <p className="more-details py-2 mb-0">
                          <a href="#">Read More </a>
                        </p> */}
                      </Col>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      md={12}
                      lg={6}
                      xl={6}
                      className="align-self-center text-center"
                    >
                      <img src={key.IMAGE} className="w-85 img-fluid fadeInLeft" />
                    </Col>
                    <Col
                      md={12}
                      lg={6}
                      xl={6}
                      className="align-self-center text-lg-start text-md-center text-sm-center py-3 align-self-center"
                    >
                      <Col className="w-75 d-md-100 mx-auto fadeInRight  maersk-regular-text">
                        <h2 className="mb-0">{key.HEADER}</h2>
                        <p className="mb-0 py-3">{key.CONTENT}</p>
                        {/* <p className="more-details py-2 mb-0">
                          <a href="#">Read More </a>
                        </p> */}
                      </Col>
                    </Col>
                  </>
                )}
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default SolutionsSection;