const config = {
  defaultPath: "/landing",
  basename: "", // only at build time to set, like /datta-able
  layout: "vertical", // vertical, horizontal
  preLayout: null, //null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: "", // mini-menu
  layoutType: "menu-dark", // menu-dark, menu-light, dark
  navIconColor: true,
  headerBackColor: "", // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: "navbar-dark", // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: "", // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: "", // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  navDropdownIcon: "style2", // style1, style2, style3
  navListIcon: "style1", // style1, style2, style3, style4, style5, style6
  navActiveListColor: "active-lightblue", // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: "title-default", // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: false,
  layout6Background:
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // used only for pre-layout = layout-6
};
export default config;
