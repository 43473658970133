export default class MsalService {
  // createGetHeader = (accessToken) => {
  //   const headers = new Headers();
  //   const bearer = `Bearer ${accessToken}`;
  //   headers.append("Authorization", bearer);

  //   return headers;
  // };
  // createGetHeader = (accessToken) => {
  //   return {
  //     headers: {
  //       Authorization: `bearer ${accessToken}`,
  //       "Content-Type": "application/json",
  //     },
  //   };
  // };
  createHeader = (accessToken) => {
    return {
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Request-Headers": "Content-Type, Authorization",
      },
    };
  };
}
