import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

let clientId;
process.env.NODE_ENV === "production" &&
process.env.REACT_APP_RUN_ENV === "production"
  ? (clientId = "0224cae2-1df0-4a74-a6c4-d05604a5d4dd")
  : (clientId = "2584d390-794a-43c6-b931-adda4233c846");

export const msalConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/05d75c05-fa1a-42e7-9cf1-eb416c396f2d",
    clientId: clientId,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// export const loginRequest = {
//   scopes: ["api://2584d390-794a-43c6-b931-adda4233c846/users.read"],
// };

let resourceScope;
process.env.NODE_ENV === "production" &&
process.env.REACT_APP_RUN_ENV === "production"
  ? (resourceScope = "api://0224cae2-1df0-4a74-a6c4-d05604a5d4dd/users.read")
  : (resourceScope = "api://2584d390-794a-43c6-b931-adda4233c846/users.read");

console.log("process.env.NODE_ENV in msal config: ", process.env.NODE_ENV);
console.log(
  "process.env.REACT_APP_RUN_ENV in msal config: ",
  process.env.REACT_APP_RUN_ENV
);
let resourceUri;
process.env.NODE_ENV === "production" &&
process.env.REACT_APP_RUN_ENV === "production"
  ? (resourceUri = "https://apiazewpmaestrodatalab.azurewebsites.net/dlabapi/")
  : (resourceUri = "https://apiazewdmaestrodatalab.azurewebsites.net/dlabapi/");

let mme_maestro_backend;
process.env.NODE_ENV === "production" &&
process.env.REACT_APP_RUN_ENV === "production"
  ? (mme_maestro_backend =
      "api://a282ff17-428f-4785-8a03-f0c8bab8a936/maestro-product-scope")
  : (mme_maestro_backend =
      "api://1f81039c-50a0-441b-8968-8c9f2abaac4a/maestro-product-scope");

export const apiConfig = {
  resourceUri: resourceUri,
  resourceScopes: [resourceScope],
};

export const loginRequestNew = {
  redirectUri: "/blank.html",
  scopes: ["User.Read", ...apiConfig.resourceScopes],
};

export const protectedResources = {
  apiMMD: {
    scopes: [mme_maestro_backend],
  },
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  datalab: {
    endpoint: [resourceUri],
    scopes: [resourceScope],
  },
};
