const DEFAULT = {
  BLANK_LINK: "#!",
  LANDING_PAGE: "/datalab/landing",
  LINEAGE_URL: "/datalab/applications/datalineage",
  LINEAGE_II_URL: "/datalab/applications/capabilities/h2o",
  DATA_PIPELINE: "/datalab/pipeline/data",
  MODEL_PIPELINE: "/datalab/pipeline/model",
  FEEDBACK_URL: "/datalab/feedback",
  RELEASES_URL: "/datalab/releases",
  COST_MANAGEMENT_URL: "/datalab/costmanagement",
  WIKI_URL: "/datalab/wiki",
  MLFLOW_URL: "/datalab/mlops/mlflow",
  SELDONCORE_URL: "/datalab/mlops/seldoncore",
  FEAST_URL: "/datalab/mlops/feast",
  // FLYTE_URL: "/datalab/mlops/flyte",
  CREATE_REPO: "/datalab/repositories/createrepo",
  PIPELINE_DASHBOARD_URL: "/datalab/pipeline/dashboards",
  REGISTER_PRODUCT_URL: "/datalab/products/registerproduct",
  PRODUCT_CATALOGUE_URL: "/datalab/products/productcatalogue",
  VIEWALL_CATALOGUE_URL: "/datalab/viewallproducts",
  // JUPYTERLAB_URL: "/datalab/compute",
  // MONITORING_URL: "/datalab/dataops/monitoringandalert",
  // CODE_ORCHESTRATOR_URL: "https://global-orchestrator-dev.maestro.maersk.com/",
  CATALOGUE_URL:
    "https://maestro-dev.insights.maersk.com/ie/applications/catalogue",
  // WIKI_URL: "https://appazewpmaestrowiki.azurewebsites.net/en/DataLab",
  CLARITY_URL:
    "https://clarity.microsoft.com/projects/view/cs8vfpl6ox/dashboard?date=Last%203%20days&Source=webazewdmaestrodatalab.azurewebsites.net",
  UNRAVEL_URL: "http://pov202111.westeurope.cloudapp.azure.com:3000/#/login",
  POSTGRES_URL: "https://pgadmin-dev.maestro.maersk.com/login?next=%2F",
  CLOUDABILITY_URL: "https://app-eu.apptio.com/cloudability",
  DREMIO_URL: "https://datalab.maestro.maersk.com:9047/",
  // H2O_URL: "http://40.114.195.185:54321/flow/index.html",
  H2O_URL: "/datalab/mlops/h2oflow",
  DATAIKU_URL: "https://dss-dev.insights.maersk.com/",
  ADX_URL: "https://adxpmlitmaestro.westeurope.kusto.windows.net/",
  SHS_URL: "/datalab/dataops/sparkhistory",
  SPLINE_URL: "/datalab/dataops/sparklineage",
  NODE_ENV: "production",
  MSAL_TOKEN_KEY: "msal.idtoken",
  DATALAB_ADMIN_ROLE: "maestro-datalab-admin",
  DATALAB_USER_ROLE: "datalab-reader",
  RECENT_ACCESS_CONST: "Accessed",
  // ADMIN_TAB: "admintab",
  // QUEUED: "Queued",
  // IN_PROGRESS: "InProgress",
  // TRAIN: "train",
  // SLASH_PUBLISH: "/publish",
  // SUCCESS: "Success",
  // UP_TO_DATE: "UpToDate",
  // PUBLISH_IN_PROGRESS: "PublishingInProgress",
  // FAIL: "Fail",
  // INTENTS: "intents",
  // EXAMPLE: "example",
  // PUBLISHED: "Published",
  // ENTITIES: "entities",
  // ONTOLOGY_PIPELINE: "IE PLATFORM SQL PIPELINE PILOT",
};

export default DEFAULT;
