import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainHero from "../../../assets/ux/assets/img/main-hero.png";
import ArrowRight from "../../../assets/ux/assets/img/arrow-right.png";
import "../../../assets/ux/assets/css/styles.css";
import "../../../assets/ux/assets/css/custom.css";
import { MAINSECTIONCONSTANT } from "../../../assets/constants/MainPageConstant";
import TypewriterMain from "./TypewriterMain";

const MainSection = (props) => {
  return (
    <Container fluid className="dotted-1 p-5">
      {/* <img src={Dotted} alt="Dotted" height="30%" width="30%" /> */}
      {MAINSECTIONCONSTANT &&
        MAINSECTIONCONSTANT.map((key, item) => {
          return (
            <Row key={item}>
              <Col
                md={12}
                sm={12}
                lg={6}
                xl={6}
                className="align-self-center order-lg-2"
              >
                <img src={key.IMAGE} className="img-fluid" />
              </Col>
              <Col
                md={12}
                sm={12}
                lg={6}
                xl={6}
                className="p-xl-5 p-lg-5 p-md-0 p-sm-0 order-lg-1 align-self-center text-lg-start text-sm-center"
              >
                <h2 className="gradient-text-1 py-1 mb-0">{key.HEADER}</h2>
                <TypewriterMain />
                <p className="lead w-100">{key.PARA}</p>
                {/* <Link className="text-link-decor" to={`/datalab/landing`}>
                    <h3 className="gradient-text-2">
                      Get Started <img src={ArrowRight} alt="Datalab" />
                    </h3>
                  </Link> */}
              </Col>
            </Row>
          );
        })}
    </Container>
  );
};

export default MainSection;
