import IMAGE from "./Imageconstant";
import COLLABORATIVEIMAGE from "../../assets/images/Collaborative_workflow.png";
import CONTROLLEDIMAGE from "../../assets/images/Controlled.png";
import COSTEFFECTIVEIMAGE from "../../assets/images/Cost_Effective.png";
import FLEXIBLECICCDIMAGE from "../../assets/images/flexible-cicd.png";
import SELFSERVICEIMAGE from "../../assets/images/self_service_customization.png";

export const MAINSECTIONCONSTANT = [
  {
    // "The Designated Data Experimentation Space"
    HEADER: "DataLab - Experimentation is the foundation towards Progression",
    IMAGE: IMAGE.MAINSECTION.MAINHEROIMAGE,
    PARA: "Datalab is an in-house Data collaborative platform provides foundation of a successful data strategy, the infrastructure to collect, store, process and analyse the data at scale. It provides a web based interface for Data exploration, analysis, visualization and supports multiple programming languages such as Python, R, SQL and Scala.",
  },
];

export const LANDINGCARDSHEADER = [
  {
    HEADER: "Problems We Solve",
    PARA: "An inhouse modernized, secured, cost effective, cloud agnostic enterprise data and experimentation platform to collaborate, scale and create business data products, validate and provide recommendations.",
  },
];

export const LANDINGCARDS = [
  {
    ID: "nav-home-tab",
    DATATARGET: "#nav-home",
    CONTROLS: "nav-home",
    EVENTKEY: "first",
    IMAGE: IMAGE.NAVLANDINGCARDS.FIRSTHOMEIMAGE,
    NAVNAME: "Cost & Security Control",
  },
  {
    ID: "nav-profile-tab",
    DATATARGET: "#nav-profile",
    CONTROLS: "nav-profile",
    EVENTKEY: "second",
    IMAGE: IMAGE.NAVLANDINGCARDS.SECONDPROFILEIMAGE,
    NAVNAME: "Integration & Experimentation",
  },
  {
    ID: "nav-contact-tab",
    DATATARGET: "#nav-contact",
    CONTROLS: "nav-contact",
    EVENTKEY: "third",
    IMAGE: IMAGE.NAVLANDINGCARDS.THIRDCONTACTIMAGE,
    NAVNAME: "Self Service & Customization",
  },
  {
    ID: "nav-access-tab",
    DATATARGET: "#nav-access",
    CONTROLS: "nav-access",
    EVENTKEY: "fourth",
    IMAGE: IMAGE.NAVLANDINGCARDS.FOURTHACCESSIMAGE,
    NAVNAME: "DataOps, MLOps, AIOps",
  },
];

export const LANDINGCARDSCONTENT = [
  {
    EVENTKEY: "first",
    FIRSTCONTENT: [
      "Data compute and I/O throughput with an optimized managed Kubernetes cluster.",
      "Implement Data governance policy and procedures.",
    ],
    SECONDCONTENT: [
      "Single point of monitoring and alerting.",
      "Regular data security, governance and audits improvements.",
    ],
  },
  {
    EVENTKEY: "second",
    FIRSTCONTENT: [
      "Integration with the Storage and data persistent mechanism.",
      "Identify and collect relevant data.",
      "Conduct exploratory Data analysis.",
    ],
    SECONDCONTENT: [
      "Define objective and hypothesis on Business use cases.",
      "Develop and execute the experiment.",
      "Analyze the results.",
    ],
  },
  {
    EVENTKEY: "third",
    FIRSTCONTENT: [
      "User friendly user interface to track and use the datasets.",
      "Role bases access controls ensuring sensitive data for authorized users.",
      "Customizable dashboards and reports.",
    ],
    SECONDCONTENT: [
      "Data exploration and analysis.",
      "Data integration.",
      "Collaboration and sharing.",
    ],
  },
  {
    EVENTKEY: "fourth",
    FIRSTCONTENT: [
      "Promote collaboration, DataOps automation, DataOps agility analytics.",
      "Data quality measurement and indexing.",
      "Data governance and policies.",
      "Data collection and preparation.",
      "Model training and validations.",
    ],
    SECONDCONTENT: [
      "Model deployment and monitoring.",
      "Model retraining and updating.",
      "Model Governance and compliance.",
      "Data driven insights and predictions.",
      "Proactive monitoring.",
    ],
  },
];

export const SOLUTIONHEADER = [
  {
    HEADER: "Our Solutions",
    PARA: "Datalab Provides the solution of spark on k8s and MLOPs framework which enhances the Enterprise Data Platform by providing efficient resource utilization, scalability, fault tolerance, containerization, multi-tenancy, ecosystem integration and dynamic resource allocation, which benefits and enable to build robust, flexible data processing and analytics solutions.",
  },
];

export const SOLUTIONCONSTANT = [
  {
    HEADER: "Cost Effectiveness",
    TRANSITION: "fadeInLeft",
    IMAGE: COSTEFFECTIVEIMAGE,
    CONTENT:
      "Efficient resource utilization by using spark on k8's. Auto scale capabilities on k8s based on workload requirements. Multi-tenancy allows different spark application to run concurrently on the same clusters ,reduce infra costs. Containerization allows spark applications can be packaged as containers and suitable for lightweight and isolated environments. Helps optimizing cost as multiple spark containers can run on a single cluster without resource contentions. Dynamic resource allocation. Spot instances and auto scaling. Infrastructure flexibility and cloud agnostic",
  },
  {
    HEADER: "Controlled & Secured",
    TRANSITION: "fadeInRight",
    IMAGE: CONTROLLEDIMAGE,
    CONTENT:
      "Authentication and authorization through RBAC in k8s. Network policies around Spark component and sensitive resources. Control the network traffic. Secrets can be mounted into Spark application as environment variable or mounted volumes ensuring the sensitive information is not exposed in application code or configuration files. Pod security policies can enforce the security on running containers with non-root users, restriction on privileged access, limiting host namespace visibility and controlling the usage of host resources. Pod Lifecycle Management by configuring appropriate pod life cycle policies ,we can ensure pods terminated gracefully ,resources are released properly and ensure unauthorized access prevented.",
  },
  {
    HEADER: "Collaborative Workflow",
    TRANSITION: "fadeInLeft",
    IMAGE: COLLABORATIVEIMAGE,
    CONTENT:
      "Shared clusters and Resource sharing. Application isolation. Job scheduler / Orchestrator. Data Sharing and Collaboration tools such as GitHub, Jira and confluence",
  },
  {
    HEADER: "Self Service & Customization",
    TRANSITION: "fadeInRight",
    IMAGE: SELFSERVICEIMAGE,
    CONTENT:
      "Governed self service provisioning and Customization of cluster configurations. Isolation, multi-Tenancy and Integration with ML/AI frameworks",
  },
  {
    HEADER: "Flexible CI/CD",
    TRANSITION: "fadeInLeft",
    IMAGE: FLEXIBLECICCDIMAGE,
    CONTENT:
      "Container orchestration. Environment reproducibility. Scalable execution and CI-CD integration",
  },
];

export const FEATURECONSTANT = [
  {
    HEADER: "Spark on K8s",
    IMAGE: IMAGE.FEATURES.SPARKK8SIMAGE,
    CONTENTLINE1:
      "Spark on k8s leverages the containerization capabilities of Kubernetes to package spark applications into containers and execute them within a k8s cluster.",
    CONTENTLINE2:
      "Each spark application is deployed as a set of containers, including the spark drivers and executors ,which are scheduled and managed by k8s.",
  },
  {
    HEADER: "Data Orchestrator - Low Code",
    IMAGE: IMAGE.FEATURES.AIRFLOWIMAGE,
    CONTENTLINE1:
      "Elyra with Airflow as dataflow orchestrator which extend the  capabilities of jupyterlab to enable the creation, editing and execution of pipelines and workflows using visual editors notebook based interface.",
  },
  {
    HEADER: "Data Orchestrator - Config Driven",
    IMAGE: IMAGE.FEATURES.SYSTEMIMAGE,
    CONTENTLINE1:
      "Running airflow DAG (directed acyclic graph) programmatically, code and config driven helps to automate the complex workflows, incorporate the decision making logic, integrate with external systems and customize the execution flow based on the specific requirements.",
  },
  {
    HEADER: "Single-user (dedicated) server profiles in Datalab",
    IMAGE: IMAGE.FEATURES.KUBERNATESPROFILESIMAGE,
    CONTENTLINE1:
      "Minimal Environment - Uses jupyter/minimal-notebook (which has everything from jupyter/base-notebook) Python, Git, Vi, Nano, tzdata, unzip etc",
    CONTENTLINE2:
      "Data Science  Environment - Uses jupyter/datascience-notebook image (jupyter/scipy-notebook & jupyter/r-notebook), Libraries (Julia, python, R, scipy, rpy2, HDFS, gradfly, Rdatasets packages)",
    CONTENTLINE3:
      "Spark environment - Uses jupyter/all-spark-notebook and its ancestor images IRKernel, rcurl, sparklyr, ggplot2packages, spylon-kernel",
  },
  {
    HEADER: "Integration with GitHub and GitHub Actions",
    IMAGE: IMAGE.FEATURES.GITHUBACTIONSIMAGE,
    CONTENTLINE1:
      "Integration Apache spark with GitHub and GitHub actions allows us to automate the speak jobs and workflows, making easier to manage and collaborate on the Data products.",
  },
  {
    HEADER: "Spark Lineage",
    IMAGE: IMAGE.FEATURES.SPARKLINEAGEIMAGE,
    CONTENTLINE1:
      "Spark lineage typically represented as a directed acyclic graph (DAG), where the nodes represent the data sources, intermediate datasets and final output. The edges represent the transformations applied to the data.",
    CONTENTLINE2:
      "The lineage graph captures dependencies between different stages and allows for tracking the back the data transformation to the source data.",
  },
  {
    HEADER: "Monitoring Spark and Spark History",
    IMAGE: IMAGE.FEATURES.SPARKHISTORYIMAGE,
    CONTENTLINE1:
      "Spark monitoring through spark UI and logging mechanism enables tracking and analysing the runtime behaviours and performance of the spark applications.",
    CONTENTLINE2:
      "Spark history provides a historical view of completed spark applications enable us to review and analyse past job executions, behaviours of spark applications, identify patterns and make informed decisions to improve performance, optimize resource allocation and diagnose issues.",
  },
];
export const TYPEWRITERCONSTANT = [
  "DataLab",
  "Data experimentation space",
  "Designated Data science system",
  "Essential toolset for data analytics",
  "Data Creativity Space",
  "Center of Digital Social research",
];

export const RELEASE_CONST = [
  {
    VERSION_HEADER: "Alpha Release ( V1.0.0 )",
    RELEASE_FEATURES: [
      "Spark on k8s",
      "ADLS Gen2 Connection",
      "Intellisense",
      "PowerBI Connection",
      "Low Code - Orchestartion",
      "Config Driven - Orchestration",
      "Github and Github Actions Integration",
      "CI-CD",
      "Integration with Pensive",
      "Spark Lineage, Spark Session Monitoring and Spark History",
      "Streamlit and Pivot",
    ],
    UPCOMING_FEATURES: [
      "MLOPS Framework",
      "Integration of Unravel on cost monitoring and recommendation",
      "Integration of Prisma cloud on security threat modelling",
      "Integration of Databook",
    ],
  },
];
