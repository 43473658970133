import React from "react";
import { Container, Col} from "react-bootstrap";
import "../../../assets/ux/assets/css/styles.css";
import "../../../assets/ux/assets/css/custom.css";
import SliderCarousel from "./SliderCarousel";
import LandingCards from "./LandingCards";
import Footer from "../Footer/Footer";
import SolutionsSection from "./SolutionsSection";
import ContactSection from "./ContactSection";
import MainSection from "./MainSection";
import "../../../assets/scss/components/menu/_sidebar.scss";

const MainPage = () => {
  return (
    <>
      <Container fluid id="layoutSidenav_content">
        {/* <main> */}
          <Col className="section-1">
            <MainSection />
          </Col>
          <Col className="section-2 p-5 bg-white">
            <LandingCards />
          </Col>
          <Col className="section-3 p-5 bg-white">
            <SolutionsSection />
          </Col>
          <Col className="section-4 p-5">
            <Container className="">
              <Col className="text-center mb-5">
                <h1 className="pseudo_border">Top Features</h1>
                <SliderCarousel />
              </Col>
            </Container>
          </Col>
          {/* <Container className="section-5 p-5">
            <LandingCarousel />
          </Container> */}
          <Col className="section-6 p-5">
            <ContactSection />
          </Col>
          <div className="section-7">
            <Container fluid className="p-0">
              <Footer />
            </Container>
          </div>
        {/* </main> */}
      </Container>
    </>
  );
};

export default MainPage;
