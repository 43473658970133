import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../../../msalConfig";
import IMAGE from "../../../../../assets/constants/Imageconstant";
import { ERRORS } from "../../../../../assets/constants/ErrorConstants";
import { GRAPHME_URI } from "../../../../App/serverConfig";
import { Buffer } from "buffer";
import RecentActivities from "../../RecentActivities";

const SignOut = () => {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const { instance, accounts } = useMsal();
  const account = instance.getActiveAccount();
  const loggedusername = accounts.map((name) => name.name);
  const [avatar, setAvatar] = useState();
  const [textAvatar, setTextAvatar] = useState();
  const request = {
    scopes: protectedResources.graphMe.scopes,
    account: account,
  };
  const getProfile = (token) => {
    axios({
      method: "get",
      url: GRAPHME_URI,
      headers: {
        Authorization: `bearer ${token}`,
      },
      responseType: "arraybuffer",
    })
      .then((res) => {
        const avatar = Buffer.from(res.data).toString("base64");
        setAvatar(avatar);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.statusText === ERRORS.MSG.NOTFOUND
        ) {
          const myNameArray = account.name.split(" ");
          const myProfile = myNameArray[0].charAt(0) + myNameArray[1].charAt(0);
          setTextAvatar(myProfile);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = (await instance.acquireTokenSilent(request)).accessToken;
        getProfile(token);
      } catch (error) {
        if (error) {
          try {
            const token = (await instance.acquireTokenRedirect(request))
              .accessToken;
            getProfile(token);
          } catch (error) {
            console.log("failed to generate acquire token redirect", error);
          }
        }
      }
    };
    fetchData();
  }, [instance]);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
     {values.map((v, index) => (
             // id="mobile" 
           <div onClick={() => handleShow(v)} key={index}>
             {show ? (
              <>
              {avatar ? (
                <img
               className="user-img"
               src={"data:image/jpeg;base64, " + avatar}
               alt="profile pic"
             />
              ): (
                <span className="login-name">{`${textAvatar}`}</span>
              )}
              </>
               ) : (
                <>
              {avatar ? (
                <img
               className="user-img"
               src={"data:image/jpeg;base64, " + avatar}
               alt="profile pic"
             />
              ): (
                <span className="login-name">{`${textAvatar}`}</span>
              )}
              </>
             )}
           </div>
         ))}
 
         <Modal
           show={show}
           fullscreen={fullscreen}
           onHide={() => setShow(false)}
           className="modal right"
         >
           <Modal.Header closeButton>
             <h2 className="recent__activities__main__header">
               Recent Activities
             </h2>
           </Modal.Header>
           {/* <Modal.Body> */}
             <RecentActivities avatar={avatar} loggedusername={loggedusername} />
           {/* </Modal.Body> */}
         </Modal>
     </>
    // <li className="user-icon">
    //   <Dropdown className="logout-dropdown ">
    //     <Dropdown.Toggle className="user-icon">
    //       {avatar ? (
    //         <img
    //           className="user-img"
    //           src={"data:image/jpeg;base64, " + avatar}
    //           alt="profile pic"
    //         />
    //       ) : (
    //         <span className="login-name">{`${textAvatar}`}</span>
    //       )}
    //     </Dropdown.Toggle>
    //     <div className="dropdown-content">
    //       <a
    //         href="/"
    //         onClick={(event) =>
    //           instance.logoutPopup().catch((e) => {
    //             console.error(e);
    //           })
    //         }
    //       >
    //         <img src={IMAGE.LOGOUT} alt={"LogOut"} className="logout" />
    //         <span>Logout</span>
    //       </a>
    //     </div>
    //   </Dropdown>
    // </li>
  );
};

export default SignOut;
