import { FaGithub } from "react-icons/fa";
import { MdComputer, MdManageAccounts } from "react-icons/md";
import { SiAzurepipelines } from "react-icons/si";
import { BiRocket, BiWorld } from "react-icons/bi";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { TiInfinityOutline } from "react-icons/ti";
import { IoColorFilterSharp } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";
import DEFAULT from "../constants/deafaultconst";
import { GoPrimitiveDot } from "react-icons/go";
import {
  AIRFLOW_URL,
  GITHUB_URL,
  JUPYTERLAB_URL,
  KUBEFLOW_URL,
  MONITORING_URL,
  SPARK_URL,
  SPLINE_URL,
  CONFIGDRIVEN_URL,
  MLFLOW_URL,
  SELDONCORE_URL,
} from "./ApplicationsConstant";

const DATALAB_ADMIN = DEFAULT.DATALAB_ADMIN_ROLE;
const DATALAB_USER = DEFAULT.DATALAB_USER_ROLE;

export const menuItem = {
  children: [
    {
      id: "repository",
      title: "Repository",
      path: GITHUB_URL,
      icon: <FaGithub size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      target: "_blank",
      // children: [
      //   {
      //     id: "repo",
      //     path: GITHUB_URL,
      //     name: "Repo",
      //     icon: <GoPrimitiveDot size={10} />,
      //     target: "_blank",
      //   },
      //   {
      //     id: "createrepo",
      //     path: DEFAULT.CREATE_REPO,
      //     name: "Create Repo",
      //     icon: <GoPrimitiveDot size={10} />,
      //     target: "",
      //   },
      // ],
    },
    {
      id: "compute",
      title: "Compute",
      path: JUPYTERLAB_URL,
      icon: <MdComputer size={20} />,
      target: "_blank",
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      // children: [],
    },
    {
      id: "pipeline",
      title: "Pipeline",
      path: "",
      icon: <SiAzurepipelines size={18} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      children: [
        {
          id: "lowcode",
          path: AIRFLOW_URL,
          name: "Low Code",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "_blank",
        },
        {
          id: "orchestrator",
          path: KUBEFLOW_URL,
          name: "Orchestrator",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "_blank",
        },
        {
          id: "configdriven",
          path: CONFIGDRIVEN_URL,
          name: "Config Driven",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "_blank",
        },
        {
          id: "dashboard",
          path: DEFAULT.PIPELINE_DASHBOARD_URL,
          name: "Dashboard",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "",
        },
      ],
    },
    {
      id: "dataops",
      title: "DataOps",
      path: "",
      icon: <TiInfinityOutline size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      children: [
        {
          id: "sparkhistory",
          path: SPARK_URL,
          name: "Spark History",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "_blank",
        },
        {
          id: "sparklineage",
          path: DEFAULT.SPLINE_URL,
          name: "Spark Lineage",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          target: "",
        },
        {
          id: "monitoring",
          path: MONITORING_URL,
          name: "Monitoring & Alert",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_ADMIN],
          target: "_blank",
        },
      ],
    },
    {
      id: "mlops",
      title: "MLOPS",
      path: "",
      icon: <IoColorFilterSharp size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      children: [
        {
          id: "experimenttracker",
          path: MLFLOW_URL,
          name: "Experiment Tracker",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          // icon: <SiMlflow size={20} />,
          target: "_blank",
        },
        {
          id: "h2oflow",
          path: DEFAULT.H2O_URL,
          name: "H2O Flow",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          // icon: <GiH2O size={20} />,
          target: "",
        },
        {
          id: "experimentenabler",
          path: SELDONCORE_URL,
          name: "Experiment enabler",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          // icon: <GiDroplets size={20} />,
          target: "_blank",
        },
        {
          id: "featurestore",
          path: DEFAULT.FEAST_URL,
          name: "Feature Store",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_USER, DATALAB_ADMIN],
          // icon: <SiGitlfs size={20} />,
          target: "",
        },
        // {
        //   id: "flyte",
        //   path: DEFAULT.FLYTE_URL,
        //   name: "Flyte",
        //   icon: <GoPrimitiveDot size={10} />,
        //   // icon: <GiMoebiusTriangle size={20} />,
        //   target: "",
        // },
      ],
    },
    {
      id: "product",
      title: "Product",
      path: "",
      icon: <BsFillBoxSeamFill size={18} />,
      accessTo: [DATALAB_ADMIN],
      children: [
        {
          id: "registerproduct",
          path: DEFAULT.REGISTER_PRODUCT_URL,
          name: "Register Product",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_ADMIN],
          target: "",
        },
        {
          id: "productcatalogue",
          path: DEFAULT.PRODUCT_CATALOGUE_URL,
          name: "Product Catalogue",
          icon: <GoPrimitiveDot size={10} />,
          accessTo: [DATALAB_ADMIN],
          target: "",
        },
      ],
    },
    {
      id: "costmanagement",
      title: "Cost Management",
      path: DEFAULT.COST_MANAGEMENT_URL,
      icon: <MdManageAccounts size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      target: "",
      // children: [],
    },
    {
      id: "feedback",
      title: "Feedback",
      path: DEFAULT.FEEDBACK_URL,
      icon: <VscFeedback size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      target: "",
      // children: [],
    },
    {
      id: "wiki",
      title: "Wiki",
      path: DEFAULT.WIKI_URL,
      icon: <BiWorld size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      target: "",
      // children: [],
    },
    {
      id: "releases",
      title: "Releases",
      path: DEFAULT.RELEASES_URL,
      icon: <BiRocket size={20} />,
      accessTo: [DATALAB_USER, DATALAB_ADMIN],
      target: "",
      //
    },
  ],
};

// export const menuItem = {
//   children: [
//     {
//       id: "repositories",
//       title: "Repositories",
//       icon: <RiGitRepositoryCommitsFill size={20} />,
//       children: [
//         {
//           id: "repository",
//           path: DEFAULT.GITHUB_URL,
//           name: "Repository",
//           icon: <FaGithub size={20} />,
//           target: "",
//         },
//         // {
//         //   id: "insights",
//         //   path: DEFAULT.CLARITY_URL,
//         //   name: "Insights",
//         //   icon: <HiOutlineLightBulb size={20} />,
//         //   target: "_blank",
//         // },
//         // {
//         //   id: "compute",
//         //   path: DEFAULT.JUPYTERLAB_URL,
//         //   name: "Compute",
//         //   icon: <MdComputer size={20} />,
//         //   target: "",
//         // },
//       ],
//     },
//     {
//       id: "data",
//       title: "Data",
//       icon: <BsDatabaseFillGear size={20} />,
//       children: [
//         {
//           id: "dataorchestrator",
//           path: DEFAULT.DATA_ORCHESTRATOR_URL,
//           name: "Data Orchestrator",
//           icon: <AiOutlineFileText size={20} />,
//           target: "",
//         },
//         {
//           id: "codeorchestrator",
//           path: DEFAULT.CODE_ORCHESTRATOR_URL,
//           name: "Code Orchestrator",
//           icon: <FiCode size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "flow",
//           path: DEFAULT.H2O_URL,
//           name: "H2O Flow",
//           icon: <GiH2O size={20} />,
//           target: "",
//         },
//         {
//           id: "postgress",
//           path: DEFAULT.POSTGRES_URL,
//           name: "Postgres SQL",
//           icon: <SiPostgresql size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "dremio",
//           path: DEFAULT.DREMIO_URL,
//           name: "Dremio",
//           icon: <GiDolphin size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "dataiku",
//           path: DEFAULT.DATAIKU_URL,
//           name: "Dataiku",
//           icon: <SiDataiku size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "imply",
//           path: "",
//           name: "Imply",
//           icon: <TbArrowsRight size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "cloudability",
//           path: DEFAULT.CLOUDABILITY_URL,
//           name: "Cloudability",
//           icon: <BiCloudLightning size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "unravel",
//           path: DEFAULT.UNRAVEL_URL,
//           name: "Unravel",
//           icon: <TiArrowRepeatOutline size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "dataexplorer",
//           path: DEFAULT.ADX_URL,
//           name: "Azure Data Explorer",
//           icon: <SiAzuredataexplorer size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "sparkhistory",
//           path: DEFAULT.SHS_URL,
//           name: "Spark History Server",
//           icon: <SiApachespark size={20} />,
//           target: "",
//         },
//         {
//           id: "spline",
//           path: DEFAULT.SPLINE_URL,
//           name: "Spline",
//           title: "",
//           icon: <SiLineageos size={30} />,
//           target: "",
//         },
//         {
//           id: "monitoring",
//           path: DEFAULT.MONITORING_URL,
//           name: "Monitoring",
//           icon: <FaEye size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "lineage",
//           path: DEFAULT.LINEAGE_URL,
//           name: "Lineage",
//           icon: <SlGraph size={20} />,
//           target: "",
//         },
//         {
//           id: "catalogue",
//           path: DEFAULT.CATALOGUE_URL,
//           name: "Catalogue",
//           icon: <TbBook size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "wiki",
//           path: DEFAULT.WIKI_URL,
//           name: "Wiki",
//           icon: <BiWorld size={20} />,
//           target: "_blank",
//         },
//         {
//           id: "feedback",
//           path: DEFAULT.FEEDBACK_URL,
//           name: "Feedback",
//           icon: <MdOutlineFeedback size={20} />,
//           target: "",
//         },
//         {
//           path: "",
//           name: "",
//           icon: "",
//         },
//         {
//           path: "",
//           name: "",
//           icon: "",
//         },
//       ],
//     },
//   ],
// };
